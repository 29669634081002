.installment {
  border-top: 1px solid $color-border;
  border-bottom: 1px solid $color-border;

  &-info {
    text-align: left;

    @include respond-to(tablet) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 35px;
      text-align: center;
    }

    @include respond-to(macbook) {
      grid-column-gap: 10px;
      padding: 0 40px;
    }

    &__item {
      display: flex;
      align-items: center;
      margin: 0 0 20px;

      @include respond-to(tablet) {
        flex-direction: column;
        margin: 0;
      }

      &:last-child {
        margin: 0;
      }
    }

    &__icon {
      width: 100px;
      height: 100px;
      background-color: $color-white;
      align-items: center;
      justify-content: center;
      display: flex;
      flex-shrink: 0;
      border-radius: 50%;
      border: 2px solid $color-blue;
      margin: 0 20px 0 0;

      @include respond-to(tablet) {
        width: 150px;
        height: 150px;
        margin: 0 0 20px;
      }

      svg {
        width: 47%;
        height: auto;
        fill: $color-blue;
      }
    }

    &__title {
      text-transform: none;
    }
  }
}
