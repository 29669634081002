html body {
  min-height: 100vh;
}

body {
  font-family: $global-font-family;
  font-size: $global-font-size;
  line-height: $global-line-height;

  color: $color-black;
  background: $color-white;

  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;

  overflow-y: scroll;

  min-width: 320px;

  &.nav-open,
  &.languages-open,
  &.modal-open {
    height: 100vh;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;

    position: relative;
    width: 100vw;
  }

  &.chat-open,
  &.chat-pre-open {
    overflow-y: hidden;
    overflow-x: hidden;

    @include respond-to(tablet) {
      overflow-y: visible;
    }
  }

  &.chat-open {
    @include respond-to(tablet_landscape) {
      .header {
        z-index: 111;
      }
    }
  }
}

body a {
  color: $color-black;

  &:hover {
    color: $color-main;
  }
}

input,
select,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  font-family: $global-font-family;
  outline: none;
}

button {
  outline: none;
  cursor: pointer;
}

.app {
  display: flex;
  flex-direction: column;

  min-height: 100vh;
}

.content {
  flex-grow: 2;

  display: flex;
  flex-direction: column;

  padding: 0 $padding;
  background: $color-white;

  @include respond-to(tablet) {
    padding: 0 $tPadding;
  }

  @include respond-to(macbook) {
    padding: 0 $tPadding 0;

    .main-page_initial & {
      padding-top: 0;
    }
  }

  @include respond-to(desktop) {
    padding: 0;

    .main-page_initial & {
      padding: 0;
    }
  }
}

.t-h2 {
  font-size: 20px;
  line-height: 27px;
  text-transform: uppercase;
  font-weight: 700;
  margin: 0;
}

.t-h3 {
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  font-weight: 700;

  @include respond-to(tablet) {
    font-size: 20px;
    line-height: 27px;
  }
}

.text-center {
  text-align: center;
}

.theme_dark {
  color: $color-dark-text;
  background: $color-dark-main;

  .content,
  .footer,
  .header__body,
  &.nav-open .header__close span,
  .filter-nav,
  .filter__header,
  .filter,
  .filter-footer,
  .chat__inner,
  .chat__spin-wrap {
    background: $color-dark-main;
  }

  .chat__attachment .ant-upload .anticon svg {
    fill: $color-white;
  }

  .chat__spin-wrap {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(31, 40, 54, 1) 100%);
  }

  a:not(.btn):not(:hover),
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .page__header-body,
  .filter.form.ant-form,
  .ant-radio-wrapper,
  .list__item,
  .form.ant-form,
  .not-found,
  .ant-select.ant-select.ant-select-open .ant-select-selection-item,
  .page__block_cover_white,
  .schedule__item {
    color: $color-dark-text;
  }

  .page__header {
    border-bottom: 1px solid $color-dark-border;
  }

  .page__header,
  .page__auth,
  .not-found {
    &:after {
      background: rgba($color-dark-main, 0.75);
    }
  }

  .page__block_cover {
    border-bottom: 1px solid $color-dark-border;
    border-top: 1px solid $color-dark-border;
  }

  .page__block_cover::before {
    background: rgba($color-dark-main, 0.75);
  }

  input {
    color-scheme: dark;
  }

  .page__block_border {
    border-bottom: 1px solid $color-main;
  }

  @include respond-to(macbook) {
    .footer__nav-wrapper {
      border-bottom: 1px solid $color-main;
    }
  }

  .form {
    .ant-input-affix-wrapper {
      border-color: $color-main;
    }

    .ant-input:not(#chat-search_chatSearch):not(#filter_search),
    .ant-input-prefix-text,
    .select-country-item__name {
      color: $color-dark-text;
    }
  }

  .ant-select.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector,
  .ant-radio-group.test__answers .ant-radio-inner {
    border-color: $color-main;
  }

  .ant-select.ant-select .ant-select-arrow {
    background-color: $color-main;
  }

  .form .ant-input-prefix .anticon svg,
  .ant-input-password-icon.anticon svg {
    fill: $color-main;
  }

  .header__buttons,
  .card__header-title,
  .ant-select {
    color: $color-white;
  }

  .header__languages-item_value:after {
    border-color: $color-dark-text transparent transparent transparent;
  }

  .program__info {
    color: $color-dark-black;
  }

  .filter .ant-select,
  .ant-select.ant-select.ant-select-open .ant-select-selection-item {
    @include respond-to(macbook) {
      color: $color-dark-black;
    }
  }

  .partners__item,
  .levels__item,
  .accreditation__item,
  .how__item-icon,
  .filter__select-dropdown.ant-select-dropdown,
  .installment-info__icon,
  .program__info-row,
  .dropdown__body {
    background-color: $color-dark-main-hover;
  }

  .filter {
    @include respond-to(macbook) {
      background-color: $color-dark-main-hover;
    }
  }

  .slider-description__photo,
  .slider-description__no-photo {
    border-color: $color-dark-text;
  }

  .filter-nav {
    border-color: $color-dark-border;
  }

  .footer__copyright {
    background-color: $color-black;
    color: $color-dark-border;

    a {
      color: $color-dark-border !important;
    }
  }

  .filter.form.ant-form,
  .filter.form .ant-input {
    @include respond-to(macbook) {
      color: $color-dark-black;
    }
  }

  .list__item-title:before {
    background: linear-gradient(to left, $color-dark-main, rgba($color-dark-main, 0));
  }

  .anticon-close-circle svg {
    fill: $color-white;

    @include respond-to(macbook) {
      fill: $color-dark-border;
    }
  }
}
