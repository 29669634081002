.ant-message {

  .ant-message-notice {
    padding: 0;

    &.move-up-leave.move-up-leave-active {
      animation: none;
    }

    @media (max-width: 1024px) {
      text-align: left;

      padding: 0 30px;
    }

    @media (max-width: 768px) {
      padding: 0 20px;
    }
  }

  .ant-message-notice:first-child {
    margin: 0;
  }

  .ant-message-notice-content {
    min-height: 80px;
    max-width: 600px;

    text-align: left;

    font-size: 20px;
    line-height: 27px;
    font-weight: 700;
    color: $color-black;

    padding: 0;

    display: inline-flex;
    border-radius: 0;
    background: transparent;
    box-shadow: none;

    @media (max-width: 1024px) {
      font-size: 16px;
      line-height: 20px;

      min-height: 40px;
      padding: 0;

      width: 100%;
      max-width: 300px;
    }
  }

  .ant-message-custom-content {
    align-self: stretch;
    display: flex;
    align-items: flex-start;

    position: relative;

    padding: 20px 30px 20px 20px;
    border-radius: 40px;
    box-sizing: border-box;

    background: $color-white;
    border: 2px solid $color-main;
    box-shadow: 3px 3px 6px 0px rgba($color-black, 0.3);

    @media (max-width: 1024px) {
      padding: 0 10px 0 50px;
      border-radius: 10px;
    }

    &.ant-message-error {
      border-color: $color-red;

      .anticon {
        background: $color-red;
      }
    }

    &.ant-message-warning {
      border-color: $color-yellow;

      .anticon {
        background: $color-yellow;
      }
    }

    &.ant-message-success {
      border-color: $color-green;

      .anticon {
        background: $color-green;
      }
    }

    .anticon {
      background: $color-main;
      border-radius: 10px 0 10px 0;
      position: absolute;
      left: -2px;
      top: -2px;
      padding: 0;
      margin: 0;

      @include respond-to(desktop) {
        margin-right: 20px;
        top: 0;
        border-radius: 100%;
        position: relative;
      }

      svg {
        width: 40px;
        height: 40px;

        fill: $color-white;
      }
    }

    span {
      padding: 5px 0;

      @media (max-width: 1024px) {
        padding: 8px 0;
      }
    }
  }
}
