.programs {
  @include respond-to(tablet) {
    display: flex;
    flex-direction: column;
  }

  &__add {
    margin: 20px 0 0;
    position: relative;

    @include respond-to(tablet) {
      grid-column: 1 / -1;
      margin: 40px 0 0;
    }

    &_short {
      @include respond-to(tablet) {
        margin: 0;
        grid-column: auto;
      }
    }

    &-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .programs__add_short & {
        @include respond-to(tablet) {
          position: absolute;
          top: 50%;
          left: 0;
          right: 0;
          transform: translateY(-50%);
        }
      }
    }

    &-subtitle {
      text-transform: none;
      text-align: center;
      margin: 0 0 10px;
    }
  }

  &-feed {
    display: grid;
    grid-gap: 20px;

    @include respond-to(tablet) {
      grid-gap: 32px;
      grid-template-columns: repeat(auto-fill, 338px);
    }

    @include respond-to(macbook) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    &__notice {
      font-size: 20px;
      line-height: 27px;
      font-weight: 700;
      text-transform: uppercase;
      text-align: center;

      @include respond-to(tablet) {
        grid-column: 1 / -1;
      }
    }

    &__wrapper {
      @include respond-to(tablet) {
        display: grid;
        grid-column-gap: 32px;
        grid-row-gap: 32px;
        grid-template-columns: repeat(auto-fill, 338px);
      }

      @include respond-to(macbook) {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
  }
}

.program {
  &__wrap .page__block:last-child {
    margin-bottom: 0;
  }

  &__info {
    &-row {
      background-color: $color-grey;
      border-radius: 10px;
      padding: 15px 20px 20px 45px;
      margin: 0 0 20px;

      &:last-child {
        margin: 0;
      }

      @include respond-to(tablet) {
        padding: 25px 20px 20px 60px;
        margin: 0 0 30px;
      }
    }

    &-title {
      position: relative;
      margin: 0 0 10px;

      @include respond-to(tablet) {
        margin: 0 0 7px;
      }

      &::before {
        content: "";
        position: absolute;
        left: -25px;
        top: 5px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: $color-main;

        @include respond-to(tablet) {
          left: -30px;
          top: 8px;
        }
      }
    }

    &-text {
      line-height: 1.3;

      @include respond-to(tablet) {
        font-size: 18px;
        line-height: 1.67;
        font-weight: 500;
      }

      p {
        margin: 0;
      }
    }
  }
}
