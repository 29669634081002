.schedule {
  position: relative;
  margin: 0 -10px;

  @include respond-to(tablet) {
    margin: 0 -1 * $tPadding;
  }

  @include respond-to(desktop) {
    margin: 0 -15px;
  }

  &__title {
    padding-right: 70px;

    @media only screen and (min-width: 920px) {
      padding-right: 180px;
    }
  }

  &__archive {
    position: absolute;
    bottom: -4px;
    right: 0;
    width: 55px;
    height: 55px;
    border-radius: 50%;

    svg {
      width: 100%;
      height: 100%;
    }

    span {
      &:last-child {
        display: none;
      }
    }

    @media only screen and (min-width: 920px) {
      width: auto;
      height: auto;
      text-transform: none;
      border-radius: 20px;
      padding: 6px 17px 4px 10px;
      min-width: 167px;

      .anticon {
        width: 26px;
        height: 26px;
        vertical-align: middle;
        margin: 0 4px 3px 0;
      }

      span {
        &:last-child {
          display: inline;
        }
      }
    }
  }

  &__slider {
    margin: 0 -10px;
    padding: 0 0 0 10px;
    overflow: hidden;

    @media only screen and (min-width: 920px) {
      overflow: visible;
    }

    @include respond-to(tablet) {
      margin: 0;
      padding: 0 0 0 15px;
    }

    @media only screen and (min-width: 920px) {
      padding: 0 15px;
    }

    @include respond-to(desktop) {
      padding: 0;
    }

    .ant-carousel & .slick-list {
      overflow: visible;

      @media only screen and (min-width: 920px) {
        overflow: hidden;
      }

      .slick-slide > div,
      .slick-slide > div > div {
        height: 100%;
      }
    }

    .slick-track {
      display: flex !important;
    }

    .slick-slide {
      height: inherit !important;
    }
  }

  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 260px;
    height: 100%;
    border: 2px solid $color-main;
    border-radius: 10px;
    overflow: hidden;

    @include respond-to(up_small) {
      width: 280px;
    }

    @include respond-to(tablet) {
      width: 100%;
    }

    &-wrap {
      height: 100%;
      padding: 0 10px 10px;

      @include respond-to(tablet) {
        padding: 0 15px 10px;
      }
    }

    &-label {
      position: absolute;
      top: 0;
      right: 0;
      background-color: $color-main;
      color: $color-white;
      font-size: 16px;
      line-height: 20px;
      font-weight: 700;
      text-align: center;
      border-radius: 0 0 0 10px;
      padding: 2px 40px 6px;

      @media only screen and (min-width: 920px) {
        font-size: 18px;
        line-height: 23px;
        padding: 2px 59px 3px;
      }
    }

    &-body {
      flex-grow: 2;
      border-top-width: 0;
      padding: 31px 0 47px 18px;

      @media only screen and (min-width: 920px) {
        padding: 35px 0 49px 18px;
      }
    }

    &-header {
      border-bottom: 2px solid $color-main;
      padding: 0 18px 5px 0;
      margin: 0 0 7px;

      @media only screen and (min-width: 920px) {
        margin: 0 18px 7px 0;
        padding: 0 0 5px;
      }
    }

    &-row {
      padding: 0 18px 0 0;
      margin: 0 0 7px;

      @media only screen and (min-width: 920px) {
        margin: 0 0 10px;
      }

      &:last-child {
        margin: 0;
      }
    }

    &-title {
      font-size: 12px;
      line-height: 16px;
      text-transform: uppercase;

      @media only screen and (min-width: 920px) {
        font-size: 14px;
        line-height: 18px;
      }
    }

    &-subtitle {
      font-size: 18px;
      line-height: 23px;
      font-weight: 700;

      @media only screen and (min-width: 920px) {
        font-size: 22px;
        line-height: 27px;
      }
    }

    &-more {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 0;
      height: 38px;
    }
  }
}
