.archive {
  &__item {
    position: relative;
    border-radius: 5px;

    @include respond-to(macbook) {
      border-radius: 10px;
    }

    .collapse__header {
      min-height: 36px;
      padding: 0 0 0 8px;

      @include respond-to(macbook) {
        display: none;
      }
    }

    .collapse__header-text {
      width: 100%;
      padding: 9px 0;
    }

    &-header {
      font-size: 14px;
      line-height: 18px;
      text-transform: initial;
      display: flex;
      padding: 0 45px 0 0;

      .archive__item-cell:first-child {
        min-width: 105px;
        margin: 0 7px 0 0;
      }
    }

    &-cell {
      display: flex;
      align-items: center;
      border-left: 2px solid $color-main;
      padding: 0 6px;
      // margin: 0 22px 0 0;

      &_hidden-m {
        @include respond-to(downMacbook) {
          display: none;
        }
      }

      @include respond-to(macbook) {
        flex-direction: column;
        align-items: flex-start;
        position: relative;
        padding: 0 0 0 12px;
        border: 0;

        &::before {
          content: "";
          position: absolute;
          top: 4px;
          left: 0;
          bottom: 5px;
          width: 2px;
          background-color: $color-main;
        }
      }
    }

    &-video-icon {
      width: 18px;
      height: 18px;
      margin: auto 4px auto auto;
      flex-shrink: 0;

      svg {
        width: 100%;
        height: 100%;

        path {
          fill: $color-main;
        }
      }
    }

    .collapse__content {
      padding: 0 45px 8px 8px;
      margin: 0;

      @include respond-to(macbook) {
        display: block;
        max-width: none;
        padding: 0 150px 16px 8px;
      }
    }

    &-content {
      font-size: 14px;
      line-height: 18px;
      font-weight: 700;
      display: grid;
      grid-template-columns: 105px 1fr;
      grid-column-gap: 7px;
      grid-row-gap: 11px;

      @include respond-to(macbook) {
        font-size: 18px;
        line-height: 23px;
        font-weight: 700;
        grid-template-columns: 133px 143px 1fr 138px 138px;
        padding: 16px 0 0 30px;
      }
    }

    .collapse__header-icon {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      height: auto;
      width: 39px;
      overflow: hidden;

      svg {
        width: 41px;
        height: 41px;
        padding: 2px 0 0 0;
      }
    }

    &.collapse__item_open .collapse__header-icon {
      border-radius: 0;

      svg {
        padding: 0 0 2px 0;
      }
    }

    .archive__item-video-wrap {
      @include respond-to(macbook) {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 128px;
      }
    }

    .archive__item-video-btn {
      padding: 4px 20px 6px;
      min-width: auto;

      @include respond-to(macbook) {
        align-items: center;
        display: flex;
        line-height: 18px;
        border-radius: 0;
        width: 100%;
        height: 100%;
      }
    }

    &-title {
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
      text-transform: uppercase;
      margin: 0 0 5px;

      @include respond-to(downMacbook) {
        display: none;
      }
    }
  }
}
