.form {
  display: flex;
  flex-direction: column;

  &.ant-form {
    color: $color-black;
  }

  &_payments {
    @include respond-to(tablet) {
      display: block;
      text-align: center;
      width: 260px;
      margin: 0 auto;
    }
  }

  &_auth {
    width: 100%;

    @include respond-to(tablet) {
      max-width: 658px;
      margin: 0 auto;
      box-sizing: border-box;

      display: grid;
      grid-template-columns: 50% 50%;
    }

    @include respond-to(desktop) {
      margin: 0 auto;
      max-width: 1260px;
    }
  }

  &__title {
    text-align: center;
    margin: 0 0 10px;

    @include respond-to(tablet) {
      margin: 0 0 20px;
    }

    .form_auth & {
      @include respond-to(tablet) {
        grid-column: 1 / 3;
      }
    }
  }

  &__note {
    text-align: center;
    margin: 0 0 10px;
    font-size: 14px;
    line-height: 20px;

    @include respond-to(tablet) {
      font-size: 16px;
      line-height: 20px;
      margin: 0 0 20px;

      .form_auth & {
        @include respond-to(tablet) {
          grid-column: 1 / 3;
        }
      }
    }

    @include respond-to(desktop) {
      max-width: 600px;
      margin: 0 auto 20px;

      .form_auth & {
        justify-self: center;
        max-width: 1260px;
      }
    }

    &_inverse {
      margin: 30px 0 0;

      @include respond-to(tablet) {
        margin: 40px 0 0;
      }
    }

    &_separator {
      margin: 10px 0;
    }

    a {
      font-weight: 700;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    p {
      margin: 5px 0;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__actions {
    display: flex;

    position: relative;
  }

  &__response {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -30px;

    white-space: nowrap;
    font-size: 16px;
    color: $color-main;
  }

  .ant-form-item {
    margin: 0 0 20px;

    @include respond-to(tablet) {
      margin: 0 0 30px;
    }
  }

  &.form_auth {
    .ant-form-item {
      @include respond-to(tablet) {
        margin: 0 20px 30px;
      }
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;

    @include respond-to(tablet) {
      .form_auth & {
        grid-column: 1 / 3;

        display: grid;
        grid-template-columns: 50% 50%;
        width: 650px;

        margin: 10px auto 0;
      }
    }
  }

  &__item {
    position: relative;
    min-width: 0;

    &_single {
      .form_auth &,
      .form_profile & {
        @include respond-to(tablet) {
          grid-column: 1 / 3;
          justify-self: center;
        }
      }

      .form_profile & {
        grid-column: 3 / 4;
        justify-self: normal;
      }
    }
  }

  .ant-input {
    height: 40px;
    border: 1px solid $color-main;
    border-radius: 10px;
    padding: 0 15px;
    background: transparent;

    font-size: 16px;
    color: $color-black;
    font-weight: 600;

    &:focus,
    &:hover {
      border-color: $color-main;
      border-width: 1px !important;
      box-shadow: none;
    }

    &::placeholder {
      color: $color-placeholder;
    }

    &-affix-wrapper {
      border: 1px solid $color-black;
      height: 40px;
      border-radius: 10px;
      padding: 0 12px 0 11px;
      box-shadow: none;
      background: transparent;

      &:focus,
      &:hover {
        border-color: $color-main;
        border-width: 1px !important;
        box-shadow: none;
        background: transparent;
      }
    }

    &-prefix {
      margin-right: 0;

      .anticon {
        width: 16px;
        height: 16px;
        margin: 0 8px 0 0;

        svg {
          width: 16px;
          height: 16px;
        }
      }

      &-text {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 600;
        color: $color-black;
      }
    }
  }

  .ant-input-affix-wrapper {
    .ant-input {
      border: none;
      padding: 0;
      height: 38px;
      background: transparent;
    }
  }

  .ant-form-item {
    .ant-form-item-label {
      margin: 0 0 10px;
      padding: 0;

      white-space: nowrap;
    }
  }

  .ant-form-item-label {
    text-align: left;
    margin: 0 0 10px;

    > label {
      font-size: 20px;
      line-height: 27px;
      font-weight: 700;

      &:after {
        display: none;
      }
    }

    > label.ant-form-item-required {
      &::before {
        display: none !important;
      }
    }
  }

  &.ant-form-vertical {
    .ant-form-item-label {
      flex-basis: auto;
    }
  }

  .ant-form-item-explain {
    color: $color-main;
    line-height: 18px;
    font-weight: 700;
    text-align: center;
    white-space: nowrap;

    @include respond-to(tablet) {
      margin-top: 1px;
    }

    @include respond-to(desktop) {
      margin-top: 3px;
    }

    &-error {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .ant-form-item-has-error {
    .ant-input {
      border-color: $color-main;

      &:not(disabled):focus,
      &:not(disabled):hover,
      &:focus,
      &:hover {
        box-shadow: none;
        border-color: $color-red;
        border-width: 2px;
      }
    }

    .ant-form-item-explain {
      color: $color-red;
    }
  }

  .ant-checkbox-wrapper {
    display: flex;

    font-size: 16px;
    line-height: 22px;
    color: $color-black;

    .auth & {
      @include respond-to(desktop) {
        width: 400px;
        margin: 0 auto;
        display: flex;
      }
    }

    .ant-checkbox {
      top: 0;
      flex-shrink: 0;

      + span {
        padding: 0 10px;
      }

      &:hover {
        .ant-checkbox-inner {
          border-color: $color-black;
        }
      }
    }

    .ant-checkbox-inner {
      border: 2px solid $color-black;
      border-radius: 6px;
      width: 22px;
      height: 22px;

      outline: none;
      box-shadow: none;

      &:after {
        border-color: $color-main;
        height: 13.14285714px;
      }
    }

    &:hover {
      .ant-checkbox-inner {
        border: 2px solid $color-black;

        &:after {
          opacity: 0.25;
          transform: rotate(45deg) scale(1) translate(-50%, -50%);
        }
      }

      .ant-checkbox-checked {
        .ant-checkbox-inner {
          &:after {
            opacity: 1;
          }
        }
      }
    }

    .ant-checkbox-checked {
      .ant-checkbox-inner {
        background: transparent;
        border-color: $color-black;
      }

      &:after {
        border: none;
      }
    }
  }

  &.form_auth {
    .btn {
      grid-column: 1 / 3;
      justify-self: center;
    }
  }

  &:not(.chat__form) .btn + .btn {
    margin: 20px 0 0;
  }

  &.form_profile,
  &.form_payments {
    @include respond-to(tablet) {
      .form__buttons-group {
        grid-column: 1 / 3;
        display: flex;
        justify-content: center;

        .btn {
          margin: 0 15px;
        }
      }
    }
  }

  &.form_profile {
    @include respond-to(macbook) {
      .form__buttons-group {
        grid-column: 1 / -1;
      }
    }
  }

  &__button-wrap {
    display: flex;
    justify-content: center;
    gap: 20px;
    grid-column: 1 / 3;
  }

  &__button-link {
    justify-self: center;
    grid-column: 1 / 3;
  }

  &__label-title {
    font-size: 16px;
    line-height: 1.25;
    font-weight: 700;
    margin: 0 0 10px;
  }

  &__search-field.ant-input-search {
    .ant-input-affix-wrapper {
      border: 2px solid $color-main;
      border-right-width: 0;
      padding: 0 13px 1px;

      &:hover,
      &:focus {
        border-width: 2px !important;
        border-right-width: 0 !important;
      }

      &:not(:last-child) {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }

      .ant-input {
        padding-bottom: 3px;
      }
    }

    & > .ant-input-group > .ant-input-group-addon .ant-input-search-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-color: $color-main;
      background: $color-main;
      box-shadow: none;
      border-radius: 0 10px 10px 0;
      padding: 0;

      &:hover {
        border-color: $color-main-hover;
        background: $color-main-hover;
      }

      span,
      svg {
        width: 20px;
        height: 20px;
      }

      svg {
        fill: $color-white;
      }
    }
  }
}

.ant-input-group-addon {
  background-color: transparent;
}

input:-webkit-autofill {
  transition: background-color 0s 600000s !important;
}

textarea.ant-input {
  line-height: 1.25;
  min-height: 40px !important;
}

.ant-input-password-icon.anticon {
  color: $color-black;

  svg {
    width: 16px;
    height: 16px;
  }
}

.react-apple-signin-auth-btn.apple-auth-btn {
  width: 40px;
  height: 40px;
  padding: 0;
  border-radius: 20px;
  border: 1px solid #dadce0;

  svg {
    height: 60px;
    width: 100%;
  }
}
