.test {
  border: none;
  margin-top: 0;

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  &__content {
    border-bottom: 0;

    &_center {
      text-align: center;
    }
  }

  &__title {
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0 0 10px;
    text-align: center;

    @include respond-to(tablet) {
      font-size: 20px;
      line-height: 27px;
    }
  }

  &__subtitle {
    text-transform: none;
    margin: 0 0 30px;

    font-size: 16px;
    line-height: 20px;
    font-weight: 700;

    text-align: center;

    &_failed {
      color: $color-red;
    }

    &_success {
      color: $color-green;
    }

    @include respond-to(tablet) {
      margin: 0 0 40px;
      font-size: 20px;
      line-height: 27px;
    }

    p {
      margin: 0;

      &:last-child {
        margin: 0;
      }
    }
  }

  &__question-img {
    display: block;
    max-width: 100%;
    margin: 0 auto 30px;

    cursor: pointer;

    @include respond-to(tablet) {
      margin: 0 auto 40px;
    }
  }

  &__note-question-img {
    margin-left: 0;
  }

  &__note {
    margin: 0 0 20px;

    @include respond-to(tablet) {
      margin: 0 0 30px;
    }

    @include respond-to(desktop) {
      margin: 0 0 40px;
    }

    &_space_top {
      margin: 20px 0 0;

      @include respond-to(tablet) {
        margin: 30px 0 0;
      }

      @include respond-to(desktop) {
        margin: 40px 0 0;
      }
    }

    &-errors {
      text-align: left;
    }

    &-errors-question {
      font-weight: 500;
    }
  }

  &__nav {
    display: flex;
    justify-content: center;

    .btn {
      min-width: 0;
      margin: 0 5px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      @include respond-to(tablet) {
        min-width: 182px;
        margin: 0 20px;
      }
    }
  }

  &__final {
    align-self: center;
    margin: 30px 0 0;

    @include respond-to(tablet) {
      margin: 40px 0 0;
    }

    @include respond-to(desktop) {
      margin: 60px 0 0;
    }
  }

  &__answers {
    width: 100%;
    max-width: 892px;
    margin-left: auto;
    margin-right: auto;
    align-self: center;
  }
}
