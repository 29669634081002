.photos {
  display: grid;
  grid-row-gap: 20px;
  margin: 15px 0 0;

  @include respond-to(tablet) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    margin: 30px 0 0;
  }

  @include respond-to(macbook) {
    grid-gap: 30px;
  }

  .ant-image {
    display: block;
    height: 220px;
    border-radius: 10px;
    transition: $global-transition;
    overflow: hidden;

    &:hover {
      box-shadow: 0 1px 12px 0 rgba($color-black, 0.15);
    }

    @include respond-to(tablet) {
      height: 148px;
    }

    @include respond-to(macbook) {
      height: 250px;
    }

    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
      margin: 0;
    }

    &-mask-info {
      font-size: 0;
      display: flex;

      span {
        display: flex;
        align-items: center;
        font-size: 25px;
        margin: 0;
      }
    }
  }
}
