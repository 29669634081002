.btn_small-icon {
  .ant-spin {
    .anticon {
      width: 20px;
      height: 20px;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}
