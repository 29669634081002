.ant-upload {
  line-height: 1;

  .upload-container & {
    width: 100%;
    justify-content: center;
    color: $color-white;
  }
}

.ant-upload-list {
  display: none;
}

.upload-container_disabled {
  .btn {
    opacity: 0.25;
    pointer-events: none;
  }
}
