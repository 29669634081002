.avatar-crop {
  text-align: center;

  .ant-modal {
    padding: 0;

    &-body {
      padding: 0;
    }
  }

  .ReactCrop {
    display: block;
    border-radius: 6px;

    &__image {
      user-select: none;
    }
  }

  .ant-modal-content {
    border-radius: 6px;
    padding: 15px;

    @include respond-to(crop) {
      padding: 20px;
    }
  }

  .ant-modal-close {
    width: 40px;
    height: 40px;
    top: 10px;
    right: 10px;
    background-color: $color-main;
    border-radius: 6px;
    transition: background-color $transition-time ease;

    &:hover {
      background-color: $color-main-hover;
    }

    svg {
      fill: $color-white;
    }
  }

  .ant-modal-close-x {
    &,
    .anticon {
      width: 100%;
      height: 100%;
    }
  }

  &__wrapper {
    @include respond-to(crop) {
      display: grid;
      grid-template-columns: auto 100px;
      grid-column-gap: 20px;
      grid-row-gap: 20px;
      align-items: center;
    }
  }

  &__preview {
    width: 100px;
    border-radius: 100%;
    margin: 20px auto;
    display: block;
  }

  &__submit {
    @include respond-to(crop) {
      grid-column: 1 / 3;
      justify-self: center;
    }
  }
}
