.profile-info {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 20px;

    @include respond-to(tablet) {
      margin: 0 0 40px;
    }

    &-source {
      width: 100px;
      margin: 0;

      svg {
        width: 100%;
        fill: $color-white;
      }
    }

    &-actions {
      display: flex;
      margin: 0 0 0 10px;

      > * {
        margin: 0 10px;
      }
    }
  }

  &__data {
    text-align: center;
    margin: 0 0 30px;

    &-value {
      display: flex;
      justify-content: center;

      &_title {
        margin: 0 0 10px;
        font-size: 16px;
        line-height: 20px;
        font-weight: 700;

        @include respond-to(tablet) {
          font-size: 20px;
          line-height: 27px;
        }
      }

      &_subtitle {
        margin: 10px 0 0;
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
      }
    }

    &-notice {
      width: 26px;
      height: 26px;
      border-radius: 13px;
      border: 2px solid $color-red;
      margin: 0 0 0 10px;

      display: inline-flex;
      justify-content: center;

      color: $color-red;
      font-size: 17px;
      line-height: 22px;
      font-weight: 700;
    }
  }

  &__form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin: 0 0 20px;

    @include respond-to(tablet) {
      width: 708px;
      margin: 0 auto 20px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 30px;
    }

    .form__item_single {
      @include respond-to(tablet) {
        grid-column: auto;
      }

      @include respond-to(macbook) {
        grid-column: auto;
        justify-self: auto;
        width: 100%;
      }
    }
  }
}

.profile-notice {
  border: 2px solid $color-red;
  border-radius: 10px;
  padding: 20px;
  margin: 0 auto 30px;

  width: 100%;
  max-width: 940px;

  text-align: center;

  &__title {
    font-size: 18px;
    line-height: 23px;
    font-weight: 700;
    margin: 0 0 10px;
  }

  &__text {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    margin: 0 0 10px;

    &:last-child {
      margin: 0;
    }
  }

  &__link {
    cursor: pointer;
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    color: $color-black;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }

    &_sending {
      opacity: 0.3;
      pointer-events: none;
    }
  }
}

.ant-avatar > img {
  object-position: top;
}
