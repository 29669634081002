.wysiwyg {
  font-size: 16px;
  line-height: 26px;

  &__title {
    margin: 0 0 30px;

    .wysiwyg__block_themes & {
      margin: 0 0 20px;
    }
  }

  &__block {
    border-top: 1px solid $color-border;
    margin: 40px 0;
    padding: 40px 0 0;

    &:first-child {
      padding: 0;
      margin-top: 0;
      border: none;
    }

    @include respond-to(tablet) {
      margin: 50px 0;
      padding: 50px 0 0;
    }

    @include respond-to(macbook) {
      margin: 60px auto;
      padding: 60px 0 0;
      width: 720px;
    }

    &_long {
      @include respond-to(macbook) {
        width: 100%;
      }
    }

    p, h2, h3, blockquote, figure, ol, li {
      margin: 20px 0;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &_themes {
      font-size: 14px;
      line-height: 20px;

      border: 2px solid $color-main;
      border-radius: 10px;
      padding: 20px;

      &:first-child {
        border: 2px solid $color-main;
        padding: 20px;
      }

      @include respond-to(tablet) {
        font-size: 16px;
        padding: 20px 30px;

        &:first-child {
          padding: 20px 30px;
        }
      }

      @include respond-to(macbook) {
        margin-bottom: 60px;
      }

      &.wysiwyg__block {

        ul {
          margin: 0;
          padding: 0;

          li {
            list-style: none;
            margin: 0 0 20px;

            &:before {
              display: none;
            }

            &:last-child {
              margin: 0;
            }

            &.active {
              a {
                color: $color-main-hover;
                text-decoration: none;
              }
            }

            a {
              font-weight: 700;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  &__button-block {
    &-container {
      display: flex;
      justify-content: center;
      margin: 20px 0 0;

      a.ant-btn {
        text-decoration: none;
        line-height: 36px;
      }
    }
  }

  h2 {
    position: relative;

    font-size: 24px;
    line-height: 32px;
    text-transform: uppercase;
    font-weight: 700;

    margin: 0 0 30px;

    &:before {
      content: "";
      display: block;
      width: 150px;
      height: 4px;
      background: $color-main;
      position: absolute;
      left: 0;
      top: 100%;
    }

    @include respond-to(tablet) {
      font-size: 40px;
      line-height: 52px;
      margin: 0 0 40px;
    }
  }

  h3 {
    margin: 20px 0;
    text-transform: none;
    font-size: 20px;
    line-height: 32px;
    font-weight: 700;

    @media (max-width: 767px) {
      font-size: 16px;
    }
  }

  &__documents {

    @include respond-to(macbook) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 30px;

      h2 {
        grid-column: 1 / 3;
        margin: 0 0 10px;
      }
    }
  }

  &__documents-item {
    display: grid;
    grid-template-columns: 50px auto;
    grid-column-gap: 15px;
    align-items: center;
    text-decoration: none ! important;
    height: 70px;
    box-shadow: 0 0 0 2px $color-main;
    border-radius: 10px;
    overflow: hidden;

    margin: 20px 0 0;

    @include respond-to(tablet) {
      margin: 30px 0 0;
      grid-column-gap: 20px;
    }

    &-icon {
      width: 50px;
      background: $color-main;

      align-self: stretch;

      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        fill: $color-white;
      }
    }

    &-label {
      font-size: 16px;
      line-height: 20px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      font-weight: 700;
      overflow: hidden;
      padding: 0 15px 0 0;
      text-decoration: none;

      @include respond-to(tablet) {
        font-size: 20px;
        line-height: 27px;
        padding: 0 20px 0 0;
      }
    }
  }

  &__steps ol {
    counter-reset: num;

    li {
      position: relative;
      font-size: 14px;
      line-height: 18px;
      min-height: 40px;
      padding: 0 0 70px 60px;

      &:before {
        counter-increment: num;
        content: counter(num);
        position: absolute;
        top: 0;
        left: 0;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        line-height: 27px;
        font-weight: 700;
        background: $color-main;
        color: $color-white;
        border-radius: 6px;
      }

      &:last-child {
        padding-bottom: 0;

        .wysiwyg__steps-tail {
          display: none;
        }
      }

      @include respond-to(tablet) {
        font-size: 16px;
        padding: 0 0 80px 60px;
      }
    }

    span {
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 40px;
    }
  }

  &__steps-tail {
    position: absolute;
    top: 50px;
    left: 19px;
    bottom: 10px;
    width: 2px;
    background: $color-main;

    &:before,
    &:after {
      content: "";
      position: absolute;
      left: -4px;
      width: 10px;
      height: 10px;
      background-color: $color-main;
      border-radius: 50%;
    }

    &:before {
      top: 0;
    }

    &:after {
      bottom: 0;
    }
  }

  .video {
    position: relative;
    max-width: 100%;
    height: auto;
    overflow: hidden;
    padding: 0 0 56.25%;

    embed,
    iframe,
    object {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    &__settings {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin: 20px 0 0;

      button {
        margin: 0 0 0 10px;
      }
    }
  }

  ul,
  ol {

    li {
      margin: 0 0 6px;
    }
  }

  ul {
    list-style: none;
    padding: 0 0 0 14px;

    li:before {
      content: "\2022";
      color: $color-main;
      font-size: 19px;
      margin: 0 8px 0 -15px;
    }
  }

  ol {
    counter-reset: item;
    padding: 0;

    ol {
      margin: 15px 0 0;
    }

    & > li > ol > li {
      font-size: 14px;
      line-height: 26px;
      font-weight: 400;
      margin: 0 0 8px;
    }

    li {
      display: block;
      margin: 0 0 11px;

      &:before {
        content: counters(item, ".") ". ";
        counter-increment: item;
        font-weight: 700;
      }
    }
  }

  a:not(.btn):not(.link_decor) {
    font-weight: 700;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  blockquote {
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    font-style: italic;

    p:last-child {
      margin: 0;
    }
  }

  figure {

    img {
      width: 100%;
      border-radius: 10px;
      margin: 0 0 10px;
    }
  }

  figcaption {
    text-align: center;
    line-height: 18px;
    font-style: italic;

    html body & a {
      color: $color-main;
    }
  }
}
