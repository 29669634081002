.works {
  position: relative;

  &__item {
    width: 100%;
    max-width: 750px;
    margin: 0 auto;
  }

  &__item-photo {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin: 0 auto 20px;
    overflow: hidden;
  }

  &__item-name {
    font-size: 19px;
    line-height: 24px;
    font-weight: 700;
    margin: 0 0 10px;
  }

  &__item-status {
    font-size: 17px;
    line-height: 22px;
  }

  &__item-divider {
    width: 100px;
    height: 1px;
    background-color: $color-border;
    border: none;
    margin: 30px auto;
  }

  &__item-link-wrap {
    display: grid;
    grid-template-columns: 50px auto;
    grid-column-gap: 10px;
    justify-content: center;
    align-items: center;
    line-height: 18px;
    margin: 0 0 30px;
  }

  &__item-link-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background-color: $color-main;
    border-radius: 10px;

    svg {
      width: 21px;
      height: auto;
      fill: $color-white;
    }
  }

  &__item-link {
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    text-decoration: underline;
    transition: $global-transition;
    margin: 0 0 5px;

    &:hover {
      color: $color-main-hover;
    }
  }

  &__feedback-wrap.ant-form-item {
    margin: 0 0 20px;
  }

  &__feedback.ant-input {
    font-size: 17px;
    line-height: 24px;
    font-weight: 400;
    min-height: 120px;
    border-color: $color-black;
    padding: 11px 15px;
  }

  &__rate {
    font-size: 19px;
    font-weight: 700;
    color: $color-main;

    &-wrap.ant-form-item {
      margin: 0 0 30px;
    }

    .ant-rate-star-first {
      display: none;
    }

    .works__rate-item {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 40px;
      min-height: 40px;
      color: $color-main;
      border-radius: 20px;
      border: 1px solid $color-main;
      padding: 0 5px;
      transition: $global-transition;

      &.active {
        color: $color-white;
        border-color: $color-main;
        background-color: $color-main;
      }
    }

    .ant-rate-star {
      margin-top: 10px;

      & > div:hover {
        transform: none;
      }

      &:not(:last-child) {
        margin-right: 20px;
      }

      &:hover {
        .works__rate-item {
          color: $color-white;
          border-color: $color-main-hover;
          background-color: $color-main-hover;
        }
      }
    }
  }

  &__btn {
    margin: 0 auto;
  }

  &__nav {
    position: relative;

    @include respond-to(macbook) {
      position: initial;
    }

    &-prev,
    &-next {
      @include respond-to(macbook) {
        margin: 35px 0 0;
      }
    }

    &-prev {
      left: 0;
    }

    &-next {
      right: 0;
    }
  }

  &__count {
    font-size: 17px;
    line-height: 22px;
  }
}
