.plan {
  &-semester {
    &__list {
      &-empty {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 40px 0 0;

        font-size: 20px;
        line-height: 27px;
        font-weight: 600;
        color: $color-placeholder;

        @include respond-to(tablet) {
          margin: 50px 0 0;
        }

        @include respond-to(desktop) {
          margin: 60px 0 0;
        }

        .anticon {
          width: 50px;
          height: 40px;
          margin: 0 0 10px;

          svg {
            width: 50px;
            height: 40px;
            fill: $color-placeholder;
          }
        }
      }

      &-item {
        &-icon {
          &_done {
            background: $color-green;
          }

          &_checking {
            background: $color-yellow;
          }

          &_payment {
            background: $color-red;
          }

          &_process {
            background: $color-dark-text;
          }
        }

        &-mark {
          &_done {
            color: $color-green;
          }

          &_checking {
            color: $color-yellow;
          }
        }

        .list__item-body {
          display: flex;
          flex-direction: column;
        }

        &-subtext {
          color: $color-grey-dark;
          font-size: 14px;
          order: 1;
        }
      }
    }
  }

  &-complete {
    position: relative;

    @include respond-to(tablet) {
      padding: 150px 0;

      &::before {
        content: "";
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        position: absolute;
        background: url("../../images/congratulations.png") no-repeat 50% 50%;
        background-size: cover;
      }
    }

    @include respond-to(desktop) {
      padding: 275px 0;
    }

    &__title {
      font-size: 16px;
      line-height: 20px;
      font-weight: 700;
      text-transform: uppercase;
      text-align: center;
      margin: 0 0 30px;

      display: none;

      @include respond-to(tablet) {
        display: block;
        font-size: 20px;
        line-height: 27px;
        margin: 0 0 15px;
      }

      @include respond-to(desktop) {
        margin: 0 0 20px;
      }

      &_mobile {
        width: 100%;
        height: 203px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        @include respond-to(tablet) {
          display: none;
        }

        &::before {
          content: "";
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          position: absolute;
          background: url("../../images/congratulations.png") no-repeat 50% 50%;
          background-size: cover;
        }
      }
    }

    &__note {
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      text-align: center;
      margin: 0;

      @include respond-to(tablet) {
        font-size: 16px;
        line-height: 26px;
        width: 50%;
        margin: 0 auto;
      }

      @include respond-to(desktop) {
        font-size: 20px;
        line-height: 30px;
        width: 40%;
      }
    }
  }
}

.plan-settings {
  display: flex;
  justify-content: space-between;
  padding: 30px 0;

  &:last-child {
    border: none;
  }

  &__title {
    white-space: normal;
    overflow: visible;
    margin: 0 50px 0 0;

    &::before {
      display: none;
    }
  }
}

.plan__doc-link .list__item-body {
  grid-row-start: span 2;
}
