.page {
  &__header {
    background: $color-main;
    margin: 0 -1 * $padding;
    border-bottom: 1px solid $color-border;

    display: flex;
    flex-direction: column;
    justify-content: center;

    position: relative;

    height: auto;
    min-height: 560px;
    padding: 110px 0 40px;

    color: $color-black;

    z-index: 0;
    overflow: hidden;

    @include respond-to(tablet) {
      margin: 0 -1 * $tPadding;
      min-height: 650px;
      padding: 110px 0 50px;
    }

    @include respond-to(macbook) {
      min-height: 464px;
      padding: 152px 0 60px;
    }

    @include respond-to(desktop) {
      margin: 0;
    }

    .page__header + & {
      margin-top: 0;
    }

    &_short {
      min-height: 0;
      padding: 90px $padding 40px;

      @include respond-to(tablet) {
        padding: 110px $tPadding 50px;
      }

      @include respond-to(desktop) {
        padding: 162px 0 60px;
      }
    }

    &_block {
      min-height: 0;
      margin-top: 20px;
      padding: 40px $padding;
      border-top: 1px solid $color-border;
      border-bottom: none;

      @include respond-to(tablet) {
        margin-top: 25px;
        padding: 50px $tPadding;
      }

      @include respond-to(desktop) {
        margin-top: 30px;
        padding: 60px 0;
      }
    }

    &:after {
      content: "";
      position: absolute;
      z-index: 2;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: rgba($color-white, 0.85);
    }

    &_dark {
      color: $color-white;

      .ant-carousel,
      .page__header-body,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: $color-white;
      }

      &:after {
        background: rgba($color-black, 0.7);
      }
    }

    &-cover {
      position: absolute;
      z-index: 1;

      object-fit: cover;

      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    &-body {
      position: relative;
      z-index: 3;
      padding: 0 $padding;
      color: $color-black;

      > * {
        &:last-child {
          margin-bottom: 0;
        }
      }

      .page__header_short & {
        padding: 0;
      }

      &_center {
        text-align: center;
      }

      &_theme {
        @include respond-to(tablet) {
          text-align: center;
        }
      }

      @include respond-to(tablet) {
        padding: 0 $tPadding;
      }

      @include respond-to(desktop) {
        width: $layoutWidth;
        margin: 0 auto;
        padding: 0;
      }
    }

    &-title {
      padding: 0;
      margin: 0 0 10px;

      @include respond-to(tablet) {
        margin: 0 0 20px;
      }

      &_lonely {
        margin: 0;
      }

      &_center {
        text-align: center;
      }

      .page__header_plan & {
        @include respond-to(desktop) {
          text-align: center;
        }
      }
    }

    &-subtitle {
      font-size: 16px;
      line-height: 20px;
      font-weight: 700;

      @include respond-to(tablet) {
        font-size: 20px;
        line-height: 27px;
      }
    }

    &-buttons {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      margin: 20px 0 0;

      @include respond-to(tablet) {
        margin: 30px 0 0;
        flex-direction: row;
        justify-content: center;
      }

      .btn {
        margin: 10px 0;

        @include respond-to(tablet) {
          margin: 0 10px;
        }

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &-payments {
      @include respond-to(tablet) {
        min-width: 318px;
      }
    }

    &_spaceless {
      margin-top: 0;
    }
  }

  &__intro {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    margin: 0 0 10px;

    @include respond-to(tablet) {
      font-size: 18px;
      line-height: 30px;
      margin: 0 0 20px;
    }

    @include respond-to(macbook) {
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      max-width: 892px;

      &_left {
        text-align: left;
        max-width: 100%;
      }
    }

    &_center {
      text-align: center;
    }
  }

  &__auth {
    position: relative;
    margin: 0 -20px;
    padding: 0 20px;

    height: 100vh;
    min-height: 650px;
    display: flex;
    align-items: center;

    @include respond-to(tablet) {
      margin: 0 -30px;
      padding: 0 30px;
      min-height: 568px;
    }

    @include respond-to(macbook) {
      min-height: 650px;
    }

    @include respond-to(desktop) {
      margin: 0;
      padding: 0;
    }

    > * {
      position: relative;
      z-index: 2;
    }

    &:before,
    &:after {
      content: "";
      position: absolute;
      background: url("../../images/request.jpg") no-repeat 100% 100%;
      background-size: cover;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;

      @include respond-to(tablet) {
        background-position: 50% 50%;
      }
    }

    &:after {
      background: rgba($color-white, 0.85);
    }

    &-intro {
      @include respond-to(tablet) {
        grid-column: 1 / 3;
      }
    }
  }

  &__title {
    margin: 0 0 30px;

    @include respond-to(tablet) {
      margin: 0 0 40px;
    }
  }

  &__back-link {
    @include respond-to(tablet) {
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__switcher {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: static;

    transform: translateY(-5px);
    margin: 0 0 25px;

    font-size: 16px;
    line-height: 20px;
    font-weight: 700;

    @include respond-to(tablet) {
      position: absolute;
      right: 0;
      margin: 0;
      height: 52px;
      font-size: 20px;
      line-height: 27px;

      justify-content: flex-start;

      button {
        margin-left: 15px;
      }
    }
  }

  .ant-spin {
    display: block;

    @include respond-to(tablet) {
      grid-column: 1 / -1;
    }
  }
}

.page__header-btn {
  margin: 20px 0 0;

  @include respond-to(tablet) {
    margin: 30px 0 0;
  }
}
