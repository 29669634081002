.empty__wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px 0 0;

  font-size: 20px;
  line-height: 27px;
  font-weight: 600;
  color: $color-placeholder;

  @include respond-to(tablet) {
    margin: 50px 0 0;
  }

  @include respond-to(desktop) {
    margin: 60px 0 0;
  }

  .anticon {
    width: 50px;
    height: 40px;
    margin: 0 0 10px;

    svg {
      width: 50px;
      height: 40px;
      fill: $color-placeholder;
    }
  }
}
