.payments-list {
  &__item {
    &-icon {
      background: $color-green;

      .payments-list__item_debt & {
        background: $color-red;
      }
    }

    &-title {
      white-space: normal;

      &::before {
        display: none;
      }
    }

    &-date {
      margin: 0 0 5px;

      @include respond-to(macbook) {
        margin: 0;
      }
    }

    &-amount {
      color: $color-green;
      margin: 10px 0 0;

      @include respond-to(tablet) {
        align-self: end;
        margin: 10px 0 0 20px;
      }

      .payments-list__item_debt & {
        color: $color-red;
      }
    }
  }
}
