.ant-radio-group {
  &.test__answers {

    @include respond-to(desktop) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-row-gap: 40px;
      grid-column-gap: 40px;
      margin: 0 0 40px;
    }

    > div {
      margin: 20px 0;

      @include respond-to(tablet) {
        margin: 30px 0;
      }

      @include respond-to(desktop) {
        margin: 0;
      }

      &:first-child {
        margin-top: 0;
      }
    }

    .ant-radio-wrapper {
      font-size: 16px;
      line-height: 20px;
      margin: 0;

      align-items: flex-start;
    }

    .ant-radio {
      margin-right: 10px;
      top: 0;

      + * {
        padding: 0;
      }
    }

    .ant-radio-inner {
      border: 2px solid $color-black;
      width: 22px;
      height: 22px;
      background: transparent;

      &:after {
        width: 10px;
        height: 10px;
        background: $color-main;
        top: 50%;
        left: 50%;
        transform-origin: 50% 50%;
        transition: all .3s cubic-bezier(.645,.045,.355,1);
        transform: translate(-50%, -50%) scale(1);
        border-radius: 5px;
        margin: 0;
      }
    }

    .ant-radio-checked {
      &:after {
        border-color: $color-main;
      }
    }

    .ant-radio-input:focus + .ant-radio-inner {
      box-shadow: 0 0 0 3px rgba($color-main, 0.08);
    }
  }
}
