.header {
  position: absolute;
  z-index: 10;
  left: 0;
  right: 0;
  top: 0;
  height: 60px;

  @include respond-to(tablet) {
    height: 70px;
  }

  @include respond-to(macbook) {
    height: 80px;
  }

  &__wrap {
    position: relative;
  }

  &-section {
    position: relative;
  }

  &__container {
    position: relative;
    width: 100%;
    margin: 0 auto;
    height: 100%;
  }

  &__body {
    background: $color-main;

    position: fixed;
    z-index: 10;
    top: 0;
    bottom: 0;
    left: 100%;
    width: 100vw;

    transition: left $transition-time ease, background $transition-time ease;

    @include respond-to(tablet) {
      width: 394px;
      left: auto;
      right: -410px;
      transition: right $transition-time ease;

      box-shadow: -3px 0 5px 2px rgba($color-black, 0.3);
    }

    .nav-open & {
      left: 0;

      @include respond-to(tablet) {
        left: auto;
        right: 0;
      }
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__logo {
    position: absolute;
    top: 20px;
    left: 20px;

    flex-shrink: 0;

    @include respond-to(tablet) {
      top: 30px;
      left: 30px;
    }

    @include respond-to(macbook) {
      left: 40px;
      top: 37px;
    }

    &-icon {
      &.anticon {
        width: 65px;
        height: 40px;
        display: none;

        @include respond-to(macbook) {
          width: 270px;
          height: 54px;
          display: block;
        }

        svg {
          width: 65px;
          height: 40px;
          fill: $color-main;

          @include respond-to(macbook) {
            fill: $color-main;
            width: 270px;
            height: 54px;
          }
        }
      }

      &_mobile {
        &.anticon {
          display: block;

          @include respond-to(macbook) {
            display: none;
          }
        }
      }
    }
  }

  &__backdrop,
  &__languages-backdrop {
    display: none;
    position: fixed;

    background: rgba($color-black, 0.8);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;

    z-index: 9;
  }

  &__backdrop {
    .nav-open & {
      display: block;
    }
  }

  &__languages-backdrop {
    background: transparent;

    .languages-open & {
      display: block;
    }
  }

  &__buttons {
    position: absolute;
    top: 20px;
    right: 20px;

    display: flex;
    align-items: center;

    > * {
      margin: 0 10px;

      @include respond-to(macbook) {
        margin: 0 20px;
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    @include respond-to(tablet) {
      top: 30px;
      right: 30px;
    }

    @include respond-to(macbook) {
      right: 40px;
      top: 40px;
    }
  }

  &__sign-in {
    display: flex;
    align-items: center;

    &.active {
      pointer-events: none;
    }

    &-icon {
      &.anticon {
        width: 40px;
        height: 40px;
        border-radius: 20px;
        background: $color-main;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 20px;
          height: 20px;
          fill: $color-white;
        }
      }
    }

    &-label {
      display: none;

      @include respond-to(macbook) {
        display: inline-flex;
        white-space: nowrap;
        margin: 0 0 0 15px;
        font-size: 18px;
        line-height: 23px;
        font-weight: 500;

        .header__sign-in:hover &,
        .header__sign-in.active & {
          color: $color-main;
        }
      }
    }
  }

  &__close {
    position: absolute;
    z-index: 10;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    right: 20px;
    top: 20px;
    background: $color-white;

    cursor: pointer;

    @include respond-to(tablet) {
      right: 30px;
      top: 30px;
    }

    span {
      display: block;
      z-index: 5;
      position: absolute;
      height: 2.33px;
      width: 20px;
      background: $color-main;
      transition: all $transition-time ease-in-out;

      top: 19px;
      left: 50%;
      transform: translateX(-50%);

      &:nth-child(1) {
        transform: translateX(-50%) rotate(45deg);
      }

      &:nth-child(2) {
        transform: translateX(-50%) rotate(-45deg);
      }
    }
  }

  &__burger {
    position: relative;
    display: flex;
    align-items: center;

    cursor: pointer;
    transition: all $transition-time ease-in-out;

    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

    &-icon {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      background: $color-main;

      position: relative;
    }

    &-label {
      display: none;

      @include respond-to(macbook) {
        display: inline-flex;
        white-space: nowrap;
        margin: 0 0 0 15px;
        font-size: 18px;
        line-height: 23px;
        font-weight: 500;

        transition: color $transition-time ease;
        will-change: color;

        .header__burger:hover & {
          color: $color-main;
        }
      }
    }

    span {
      display: block;
      z-index: 5;
      position: absolute;
      height: 2.33px;
      width: 20px;
      background: $color-white;
      transition: all $transition-time ease-in-out;

      left: 50%;
      transform: translateX(-50%);

      &:nth-child(1) {
        top: 13px;
      }

      &:nth-child(2),
      &:nth-child(3) {
        top: 19px;
      }

      &:nth-child(4) {
        top: 25px;
      }
    }
  }

  &__nav {
    flex-grow: 2;

    padding: 100px $padding 60px;
    background: transparent;
    border: none;

    overflow-y: auto;

    a {
      text-decoration: none;
      color: $color-white;
      transition: $global-transition;

      &:hover {
        color: $color-white;
        text-decoration: underline;
      }
    }

    @include respond-to(tablet) {
      padding: 100px $tPadding 60px;
    }

    @include respond-to(macbook) {
      padding: 100px 40px 60px;
    }

    .nav-open & {
      display: flex;
      flex-direction: column;
    }

    &-parent {
      display: flex;
      flex-direction: column;
      margin: 0 0 30px;

      &:last-child {
        margin: auto 0 0 0;
      }
    }

    &-label {
      font-size: 20px;
      line-height: 27px;
      font-weight: 700;
      margin: 0 0 20px;
      color: #fff;
    }

    &-item {
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
      margin: 0 0 15px;
      color: $color-white;
      text-decoration: none;
      cursor: pointer;

      &:hover {
        color: $color-white-hover;
      }

      &.active {
        font-weight: 700;
        text-decoration: underline;
      }
    }

    &-submenu {
      margin: 0 0 0 15px;
    }
  }

  &__notice {
    z-index: 102;
    background: $color-main;
    color: $color-white;
    padding: 20px;
    position: relative;

    @include respond-to(tablet) {
      padding: 30px;
    }

    &-wrapper {
      display: flex;
      flex-direction: column;

      max-width: 1260px;
      margin: 0 auto;

      @include respond-to(tablet) {
        flex-direction: row;

        > * {
          flex-grow: 2;
        }
      }
    }

    &_hide {
      display: none;
    }

    &-close {
      align-self: center;
      margin: 10px 0 0;
      height: 40px;
      width: 40px;
      min-width: 40px;
      padding: 0;
      border-radius: 6px;
      border: none;

      @include respond-to(tablet) {
        flex-shrink: 0;
        flex-grow: 0;
        margin: 0;
      }

      .anticon {
        width: 40px;
        height: 40px;

        svg {
          fill: $color-main;
        }
      }
    }

    &-icon {
      display: none;

      @include respond-to(macbook) {
        flex-shrink: 0;
        flex-grow: 0;
        margin: 0 30px 0 0;

        display: block;

        border-radius: 100%;
        background: $color-white;

        width: 40px;
        height: 40px;

        align-self: center;

        .anticon {
          display: flex;
        }

        svg {
          width: 40px;
          height: 40px;
          fill: $color-main;
        }
      }
    }

    &-link {
      font-weight: 700;
      line-height: 20px;
      color: $color-white;
      text-decoration: underline;
      cursor: pointer;

      @include respond-to(tablet) {
        font-size: 16px;
      }

      &:hover {
        text-decoration: none;
      }

      &_sending {
        pointer-events: none;
      }
    }

    p {
      font-size: 14px;
      line-height: 18px;
      font-weight: 500;

      margin: 10px 0;

      @include respond-to(tablet) {
        max-width: 80%;

        &.header__notice-title {
          strong {
            font-size: 18px;
            line-height: 23px;
            font-weight: 700;
          }
        }
      }

      @include respond-to(macbook) {
        font-size: 18px;
        line-height: 30px;

        &.header__notice-title {
          strong {
            font-size: 20px;
            line-height: 27px;
            font-weight: 700;
          }
        }
      }

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      strong {
        font-size: 16px;
        line-height: 20px;
        font-weight: 700;
      }
    }
  }

  &__notice-locale {
    background: $color-main;
    position: relative;
    padding: 20px;

    @include respond-to(tablet) {
      padding: 30px;
    }

    @include respond-to(desktop) {
      padding: 40px 0;
    }

    &-wrapper {
      display: grid;

      grid-template-columns: 1fr 40px;
      grid-column-gap: 40px;
      grid-row-gap: 10px;

      position: relative;

      @include respond-to(desktop) {
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        grid-column-gap: 0;
      }
    }

    &-info-icon {
      display: none;
      width: 40px;
      height: 40px;
      border-radius: 20px;
      background: $color-white;

      svg {
        fill: $color-blue;
        height: 40px;
      }

      @include respond-to(desktop) {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &-text {
      font-size: 16px;
      line-height: 20px;
      font-weight: 700;
      color: $color-white;

      @include respond-to(desktop) {
        margin: 0 30px;
        font-size: 20px;
        line-height: 27px;
      }
    }

    &-action {
      background: $color-white !important;
      border-color: $color-white !important;
      color: $color-main;
      margin-right: auto;

      &:hover {
        color: $color-main-hover;
      }
    }

    &-close {
      grid-row: 1 / 3;
      grid-column: 2 / 3;
      align-self: center;
      background: $color-white !important;
      display: flex;

      svg {
        fill: $color-main;
      }

      &:hover {
        background: $color-white;

        svg {
          fill: $color-main-hover;
        }
      }

      &:active {
        svg {
          fill: $color-main-active;
        }
      }

      @include respond-to(desktop) {
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -20px;
      }
    }
  }

  &__languages {
    display: flex;
    flex-direction: column;
    position: relative;
    user-select: none;
    padding: 5px 18px 5px 9px;
    margin: 0 0 0 20px;
    z-index: 10;

    .languages-open & {
      background: $color-white;
      background: transparent;
    }

    &-wrapper {
      .languages-open & {
        display: block;
        background: $color-blue;
        position: absolute;
        left: 0;
        top: 0;
        padding: 6px 18px 4px 9px;
      }
    }

    &-item {
      font-size: 16px;
      line-height: 1.2;
      font-weight: 700;
      text-decoration: none;
      text-transform: uppercase;
      width: 31px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;

      cursor: pointer;
      transition: color $transition-time ease;
      display: none;

      &:hover {
        color: $color-placeholder;
      }

      &_hide {
        display: none;
      }

      .languages-open &:not(.header__languages-item_hide) {
        color: $color-white;
        display: flex;
        width: 31px;
        height: 30px;
        border-bottom: 1px solid $color-main-hover;

        &:last-child,
        &:nth-last-child(2) {
          border: none;
        }

        &:hover {
          color: $color-border;
        }
      }

      &_value {
        display: flex;

        transition: color $transition-time ease;
        position: relative;

        &:after {
          content: "";
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 7px 4px 0 4px;
          border-color: $color-black transparent transparent transparent;

          position: absolute;
          left: 100%;
          top: 50%;
          margin-top: -3px;

          transition: border-color $transition-time ease;
        }

        &:hover {
          &:after {
            border-color: $color-placeholder transparent transparent transparent;
          }
        }
      }

      &_active {
        position: relative;
        display: none;

        &:after {
          content: "";
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 7px 4px 0 4px;
          border-color: $color-white transparent transparent transparent;

          margin: 0 0 0 5px;

          display: none;

          .languages-open & {
            position: absolute;
            top: 50%;
            margin-top: -3px;
            margin-left: 0;
            left: 100%;
            display: block;
            border-width: 0 4px 7px 4px;
            border-color: transparent transparent $color-white transparent;
          }
        }
      }
    }
  }
}
