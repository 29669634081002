.socials {
  display: flex;

  .footer & {
    @include respond-to(macbook) {
      justify-content: flex-end;
    }
  }

  &__item {
    width: 40px;
    height: 40px;
    border-radius: 6px;
    background: $color-main;

    margin-right: 20px;

    display: flex;
    justify-content: center;
    align-items: center;

    transition: background $transition-time ease;

    @include respond-to(tablet) {
      margin-right: 30px;
    }

    .socials_share & {
      width: 20px;
      height: 20px;

      margin-right: 10px;
      border-radius: 3px;
    }

    &:hover {
      background: $color-main-hover;
    }

    @include respond-to(desktop) {
      width: 40px;
      height: 40px;
    }

    &:last-child {
      margin: 0;
    }

    svg {
      fill: $color-white;
      width: 100%;
    }
  }
}