.documents {
  @include respond-to(desktop) {
    display: flex;
    justify-content: space-around;
  }

  &__item {
    margin: 0 0 30px;

    &:last-child {
      margin: 0;
    }

    @include respond-to(desktop) {
      margin: 0;
    }
  }
}