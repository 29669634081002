.notification {
  position: relative;
  background-color: $color-main;

  font-size: 16px;
  line-height: 20px;
  color: $color-white;

  margin: 0 -20px;
  padding: 20px;

  &::before {
    content: "";
  }

  @include respond-to(tablet) {
    margin: 0 -30px;
    padding: 20px 30px;
  }

  @include respond-to(desktop) {
    font-size: 20px;
    line-height: 27px;
    margin: 0;
  }

  &__inner {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
  }
}
