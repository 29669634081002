.document {
  display: grid;
  grid-template-columns: 100px auto;
  grid-column-gap: 20px;

  @include respond-to(tablet) {
    grid-template-columns: 150px auto;
    grid-column-gap: 30px;
  }

  @include respond-to(desktop) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 250px;
  }

  &__icon {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 2px solid $color-main;
    display: flex;

    @include respond-to(tablet) {
      width: 150px;
      height: 150px;
    }

    .anticon {
      width: 100px;
      height: 100px;

      @include respond-to(tablet) {
        width: 150px;
        height: 150px;
      }

      svg {
        width: 47px;
        height: 100px;
        fill: $color-main;

        @include respond-to(tablet) {
          width: 65px;
          height: 150px;
        }
      }
    }

    &_disabled {
      border-color: $color-main-disabled;

      .anticon {
        svg {
          fill: $color-main-disabled;
        }
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;

    @include respond-to(tablet) {
      justify-content: center;
    }
  }

  &__title {
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
    font-weight: 700;
    flex-grow: 2;
    width: 90%;
    margin: 0;

    @include respond-to(tablet) {
      flex-grow: 0;
      margin: 0 0 15px;
      max-width: 235px;

      font-size: 20px;
      line-height: 27px;
    }

    @include respond-to(desktop) {
      text-align: center;
      max-width: 100%;
      width: 100%;
      margin: 20px 0;
    }
  }

  &__actions {
    display: flex;

    > span {
      display: flex;
    }

    > * {
      margin: 0 10px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__actions {
    @include respond-to(desktop) {
      align-self: center;
    }
  }

  .ant-spin {
    display: block;
  }
}