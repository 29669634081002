.ant-popover {
  &-arrow {
    display: none;
  }

  &-inner {
    border-radius: 10px;
    margin: 0 10px 0 0;

    @include respond-to(desktop) {
      margin: 0 25px 0 0;
    }
  }

  &-inner-content {
    padding: 10px;
  }
}
